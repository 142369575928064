import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Grid, TextField, Autocomplete, useMediaQuery } from "@mui/material";
import { templateMasterListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "50% !important",
  height: "40% !important",
};



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "tableName",
    label: "Table Name",
    align: "left",
    minWidth: 40,
  },

  // {
  //   id: "unitType",
  //   label: "Unit Type",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(id, instrumentName, tableName, unitType) {
  return {
    id,
    instrumentName,
    tableName,
    unitType,
  };
}

export function TablesList() {
  const templateMasterListGuides = templateMasterListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Instrument Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [clientList, setClientsList] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [staticDsTableArray, setDSStaticTableArray] = React.useState([]);
  const [certStaticTableArray, setCertStaticTableArray] = React.useState([]);
  const [tableName, setTableName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowDaata, setRowData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const [pushIArray, setPushIArray] = React.useState([]);  
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const ref = useRef(null);
  const handleClose = () => setOpen(false);
  const handleOpen = (row) => {
    setTableName(row.tableName);
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetStaticTables?_where=(id,eq,${row.id})`)
      .then((res) => {
        setDSStaticTableArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          };
      });

    axiosWithToken
      .get(url + `certificateStaticTables?_where=(id,eq,${row.id})`)
      .then((res) => {
        setCertStaticTableArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          };
      });

    setOpen(true);
  };
  
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getInstrumentsList();
    getClientList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Instrument Name");
    setSearchKey("");
    setSearched(false); 
  };

  const getClientList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Instrument Name": "instrumentName",
        "Table Name": "tableName",
      }[searchBy];

      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `select pl.instrumentId,pr.instrumentName,pl.tableName,pl.unitType,pl.id from datasheetStaticTables as pl join instruments as pr on pl.instrumentId = pr.id ${whereConditions} order by pl.id DESC `,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setClientsList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          };
      });
  };

  const submitSiteDelete = async (id) => {
    const url = BASE_URL;

    try {
      await axiosWithToken.delete(`${url}datasheetStaticTables/${id}`);
      
    } catch (error) {
      if (error.message !== "request_aborted") {
            toast.error("Error deleting template table!");
          }
    }

    try {
      await axiosWithToken.delete(`${url}certificateStaticTables/${id}`);
      toast("Template table deleted!");
    } catch (error) {
      if (error.message !== "request_aborted") {
            toast.error("Error deleting certificate table!");
          }
    }

      try {
        await axiosWithToken.post(`${url}dynamic`, {
          query: `DELETE FROM datasheetStaticReadings WHERE tableId = ${id};`,
        });
      } catch (error) {
        if (error.message !== "request_aborted") {
          toast.error("Error deleting datasheet static readings!");
        }
      }

      try {
        await axiosWithToken.post(`${url}dynamic`, {
          query: `DELETE FROM certificateStaticReadings WHERE tableId = ${id};`,
        });
        setTimeout(refresh, 500);
      } catch (error) {
        if (error.message !== "request_aborted") {
          toast.error("Error deleting certificate static readings!");
        }
      }
    
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const generateRowData = (clientList1) => {
    const newRowData = clientList?.map((client) => {
      return createData(
        client?.id,
        client?.instrumentName,
        client?.tableName,
        client?.unitType
      );
    });

    setRowData(newRowData);
  };

  const generatePushIArray = (instrumentArray) => {
    const newPushIArray = instrumentArray
      .filter((instrument) => instrument.instrumentName)
      .map((instrument) => {
        return {
          label: instrument.id + ", " + instrument.instrumentName,
        };
      });

    setPushIArray(newPushIArray);
  };
  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const handleSubmit = async (e) => {
    if (instrumentId != null) {
      var jsData = staticDsTableArray[0];
      var certData = certStaticTableArray[0];
      jsData.instrumentId = instrumentId;
      certData.instrumentId = instrumentId;
      delete jsData.id;
      delete jsData.lastModified;
      delete certData.id;
      delete certData.lastModified;
      let url = BASE_URL;
      axiosWithToken
        .post(url + "datasheetStaticTables", jsData)
        .then((res) => {
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong3!");
          }
        });
      axiosWithToken
        .post(url + "certificateStaticTables", certData)
        .then((res) => {
          toast("Table copied successfully !");
          setTimeout(refresh, 500);
          // setTimeout(refresh, 1000);
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong3!");
          }
        });
    } else {
      toast.error("Please select atleast one instrument!");
    }
  };

  useEffect(() => {
    generateRowData(clientList);
    generatePushIArray(instrumentArray);
  }, [clientList, instrumentArray]);

  useEffect(() => {
    if (searched==false) {
    getClientList();
    getInstrumentsList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          value={searchBy}
          id="template_master_list_searchby"
          options={[
            { key: "instrumentName", label: "Instrument Name" },
            {
              key: "tableName",
              label: "Table Name",
            },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField
        style={{ width: "100%" }}
          id="template_master_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          className="Listinputcss"
          variant="outlined"
          value={searchKey}
          inputProps={{
            autoComplete: "off",
          }}
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={1}  textAlign={"left"}>
        <Button
          id="template_master_list_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
        {filterModal()}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h5 className="text-center">
                <u>Table Name: {tableName}</u>
              </h5>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    id="combo-box-demo"
                    options={
                      instrumentArray != null
                        ? instrumentArray.map((item) => {
                            return {
                              id: item.id,
                              instrumentName: item.instrumentName,
                            };
                          })
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.id + ", " + option.instrumentName
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Instrument" />
                    )}
                    onInputChange={(event, newInputValue) => {
                      setInstrumentId(newInputValue.split(",")[0]);
                 }}
                />
                </Grid>
              </Grid>
              <br />
              <Toolbar style={{ padding: "0px", overflow: "auto" }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ m: 0 }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Toolbar>
            </Box>
          </Modal>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
            <Button
              id="template_master_list_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/master/editStaticTable/0"
            >
              <b>ADD NEW TEMPLATE</b>
            </Button>
            </Toolbar>
          <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start" >
            <Button
              onClick={() => {
                templateMasterListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
          </Box>
          </div>
          {!isMobile && filterSection()}
       
     <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="template_master_list_table"
         sx={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata?.length > 0 ? (
          rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Template" placement="top-start">
                        <Button
                          id="template_master_list_edit"
                          component={Link}
                          to={`/master/editStaticTable/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Copy Template" placement="top-start">
                        <span id="template_master_list_copy">
                          <Button
                            id={row.id}
                            key={row.id}
                            ref={ref}
                            disabled={!(editAccess?.includes(5) || editAccess?.includes(0))}
                            onClick={() => {
                              handleOpen(row);
                            }}
                          >
                            <ContentCopyIcon />
                          </Button>
                        </span>
                      </Tooltip>

                      <Tooltip title="Delete Template" placement="top-start">
                        <Button
                          id="template_master_list_delete"
                          disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete Template?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon 
	  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ToastContainer />
    </TableContainer>
       
    </ListWrap>
  );
}