import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from "@mui/icons-material/Search";
import { Grid, TextField, Autocomplete, Stack, Chip, Checkbox } from "@mui/material";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { toast } from "react-toastify";
import ListWrap from "../commonComponent/listWrap";

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate No.", minWidth: 40 },

  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ranges",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ducId",
    label: "Duc Id",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },

  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  companyName,
  certificateNumber,
  InstrumentId,
  ranges,
  lc,
  accuracy,
  serialNumber,
  ducId,
  make,
  model,
  calibrationDate,
  dueDate,
  companyHierarchy
) {
  return {
    id,
    ulrNo,
    companyName,
    certificateNumber,
    InstrumentId,
    ranges,
    lc,
    accuracy,
    serialNumber,
    ducId,
    make,
    model,
    calibrationDate,
    dueDate,
    companyHierarchy,
  };
}
let notNeededColumn = ["id"];
export default function DeptAnalyticsReport() {
  const [page, setPage] = React.useState(0);
  const [discipline, setDiscipline] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [data, setData] = React.useState([]);
  const [disciplineID, setDisciplineID] = React.useState([]);
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [finalData, setFinalData] = React.useState([]);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [settingList, setSettingList] = React.useState({});
  const [totalInstrument, setTotalInstrument] = React.useState(0);
  const [totalCalibrated, settotalCalibrated] = React.useState(0);
  const [searched, setSearched] = React.useState(false);
  const [pendingCalibrated, setPendingCalibrated] = React.useState(0);
  // console.log({ statusFilter });
  // console.log({ disciplineID, data , totalCalibrated, pendingCalibrated, totalInstrument, totalCalibrated});

  const FilterData = () => {

    const filteredData = data?.filter((datas) => {
      const isDisciplineIncluded =
        disciplineID.length === 0 || disciplineID.includes(datas.disciplineId);
      
      // Create date objects if fromDate and toDate are provided
      const fromDateObj = fromDate ? new Date(fromDate) : null;
      const toDateObj = toDate ? new Date(toDate) : null;
      const calibrationDateObj = new Date(datas.calibrationDate);
  
      // Date range check only if status is not 'pending'
      const isDateInRange =
        statusFilter !== "pending" &&
        ((!fromDateObj && !toDateObj) ||
        (fromDateObj && calibrationDateObj >= fromDateObj.setHours(0, 0, 0, 0) &&
        (!toDateObj || calibrationDateObj <= toDateObj.setHours(23, 59, 59, 999))));
  
      return isDisciplineIncluded && (statusFilter !== "pending" ? isDateInRange : true);
    });
  
    setTotalInstrument(filteredData.length);

    let resultProductData;

    if (statusFilter === "all") {
      resultProductData = filteredData;
    } else if (statusFilter === "completed") {
      resultProductData = filteredData.filter((datas) => {
        const isCompleted = datas.totalReadings !== 0;
        return isCompleted;
      });
    } else if (statusFilter === "pending") {
      // Filter for pending status: calibrationDate should be null
      resultProductData = filteredData.filter((datas) => {
        const isPending = datas.calibrationDate === null;
        return isPending;
      });
    }
    // console.log(resultProductData);
  
    setFinalData(resultProductData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDiscipline = () => {
    axiosWithToken
      .get(BASE_URL + `discipline?_where=(status,eq,1)`)
      .then((res) => {
        setDiscipline(res.data);
      })
      .catch((error) => console.log(error));
  };
  var pushArray = [];

  for (let i = 0; i < discipline.length; i++) {
    pushArray.push({
      id: discipline[i].id,
      label: `${discipline[i].id}, ${discipline[i].name}`,
    });
  }

  const getData = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, {
        query:
          "SELECT client.companyName, client.contact, client.address, inst.instrumentName,cert.ULRNo, cert.certificateNumber, ds.calibrationDate, ds.nextDueDate, client.id AS clientId, ds.id AS dsId, ds.lc, ds.DUCID, ds.make, ds.model, ds.ranges, srfs.companyHierarchy, ds.accuracy,ds.totalReadings , ds.serialNumber, discipline.name AS disciplineName, discipline.id AS disciplineId FROM datasheets ds JOIN clients client ON ds.clientId = client.id JOIN instruments inst ON ds.instrumentId = inst.id JOIN srfs ON ds.serviceReqNumber = srfs.serviceReqNumber JOIN certificates cert ON cert.id = ds.id JOIN discipline ON inst.disciplineId = discipline.id",
      })
      .then((res) => {
        setData(res.data);
        settotalCalibrated(res.data.length);
        setTotalInstrument(res.data.length);
        const pendingCount = res.data.filter(
          (item) => item.calibrationDate === null
        ).length;
        setPendingCalibrated(pendingCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < finalData.length; i++) {
      idAr.push(finalData[i].id);
      rowData.push(
        createData(
          finalData[i].id,
          finalData[i].ULRNo,
          finalData[i].companyName,
          finalData[i].certificateNumber,
          finalData[i].instrumentName,
          finalData[i].ranges?.split("||").map((range) => {
            let tmp = range?.split("|");
            tmp[0] = tmp[0]?.split("#");
            if (tmp[1]) {
              tmp[1] = tmp[1]?.split("#");
            }
            return tmp[1]
              ? `${tmp[0][0]} to ${tmp[1][0]}${
                  tmp[0][1] ? ` ${tmp[0][1]}` : ""
                }`
              : `${tmp[0][0]}${tmp[0][1] ? ` ${tmp[0][1]}` : ""}`;
          }),
          finalData[i].lc?.replaceAll("|", ", ")?.replaceAll("#", " "),
          finalData[i].accuracy?.replaceAll("|", ", ")?.replaceAll("#", " "),
          finalData[i].serialNumber,
          finalData[i].DUCID,
          finalData[i].make,
          finalData[i].model,
          finalData[i].calibrationDate
            ? moment(finalData[i].calibrationDate).format("DD-MM-YYYY")
            : "",
          finalData[i].nextDueDate
            ? moment(finalData[i].nextDueDate).format("DD-MM-YYYY")
            : "",
          finalData[i].companyHierarchy
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  }

  const search = () => {
    setSearched(true);
    FilterData();
  };
  const clearSearch = () => {
    setSearched(false); 
    setStatusFilter("all");
    setToDate("");
    setFromDate("");
    setDisciplineID([]);
   };
  useEffect(() => {
    getDiscipline();
    getData();
    fetchSettings();
  }, []);
  useEffect(() => {
    initializeRowTable();
    settotalCalibrated(finalData.length);
    const pendingCount = finalData.filter(
      (item) => item.calibrationDate === null
    ).length;
    setPendingCalibrated(pendingCount);
  }, [finalData]);
  useEffect(() => {
    if(searched==false){
      FilterData();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);
  return (
    <ListWrap>

        <Grid
          container
          spacing={2}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} textAlign={"left"}>
            <Autocomplete
            className="Listinputcss"
              size="small"
              id="combo-box-demo"
              options={pushArray}
              disableCloseOnSelect
              multiple
              value={pushArray.filter((option) => disciplineID.includes(option.id))}
              renderInput={(params) => (
                <TextField {...params} label="Select Discipline" />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={disciplineID.includes(option.id)}
                  />
                  {option.label}
                </li>
              )}
              onChange={(event, value) => {
                // console.log("value",value)
                setDisciplineID([...(value?.map((e) => e.id) || [])]);
                setCalData("");
                setSearched(false); 
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <TextField
              id="Start Date"
              label="Date From"
              variant="outlined"
              type="date"
              className="Listinputcss"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={statusFilter === "pending" ? "" : fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                setCalData("");
                setSearched(false);
                setStatusFilter("all"); 
              }}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <TextField
              id="Start Date"
              label="Date To"
              variant="outlined"
              type="date"
              className="Listinputcss"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={statusFilter === "pending" ? "" : toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                setCalData("");
                setSearched(false); 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2} textAlign={"left"}>
            <TextField
              select
              label="Status"
              size="small"
              className="Listinputcss"
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value);
                setSearched(false);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              style={{ width: "100%" }}
            >
              <option value="all">All</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
            </TextField>
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            color={searched ? "error" : "primary"}
            sx={{ m: 0 }}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
           {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={2} textAlign={"left"}>
          {ids.length ? (
            <div>
              <ExcelDownload
                finalData={calData}
                notNeededColumn={notNeededColumn}
              />
            </div>
          ) : (
            ""
          )}
          </Grid>
        </Grid>

        <Stack direction="row" spacing={1} sx={{ margin: "1rem 0rem" }}>
          <Chip label={`Total instruments : ${totalInstrument}`} />
          {totalCalibrated - pendingCalibrated > 0 && (
            <Chip
              label={`Calibrated instruments : ${
                totalCalibrated - pendingCalibrated
              }`}
            />
          )}
          {pendingCalibrated > 0 && (
            <Chip label={`Pending Instruments : ${pendingCalibrated}`} />
          )}
        </Stack>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) =>
                  !settingList["companyHierarchy"] &&
                  column.id === "companyHierarchy" ? null : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {calData?.length > 0 ? (
                calData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value = row[column.id];

                          if (column.id == "id") {
                            value = page * rowsPerPage + index + 1;
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    <h6>
                      <b>Data Not Found</b>
                    </h6>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={calData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      
    </ListWrap>
  );
}
