export const initialState = {
  datasheetDetails: {
    id: 0,
    instrumentId: 0,
    ranges: "",
    lc: "",
    range_low: "",
    range_high: "",
    accuracy: "",
    tableDisplayStatus: 1,
  },

  certificateDetails: {
    certificateNumber: "",
    ULRNumber: "",
  },

  disciplineDetails: {
    documentNumber: "",
    issueNo: "",
    issueDate: "",
    amendmentNo: "",
    amendmentDate: "",
  },

  srfDetails: {
    srfNumber: "",
    entryDate: "",
  },
  standardMasterDetails: {
    standardMasterIds: "",
  },

  instrumentDetails: null,

  // master data
  units: [],
  unitSymbols: [],
  standardMasterArray: [],
  standardRanges: null,
  supportiveStandardMasterArray: [],

  // referenced data
  referenceData: {},

  // data associated with observed readings
  datasheetStaticReadings: [],
  staticReadingRows: [],
  selectedStandardIds: [],
  selectedStandardMasters: [],
  selectedStandards: [],
  selectedSupportiveRanges: [],
  staticTables: null,
  certificateStaticTables: [],

  readingsGroupByDetails: null,
  // data format
  // {
  //   valuesPerRow: null // format => rowId: value
  // }

  cmcDetails: {
    cmcReadings: []
  },

  // indicators
  indicators: {
    complianceStatus: true,
    rowWiseComplianceStatus: {},
    isShowAmendment: false,
    isCalTypeSetting: false,
    outOffRangeTracker: {}
  },

  //OpinionAndAnalysisTable
  analysisSelectedOption: {
    accepted: '',
    calibrated: '',
    limitedUse: '',
    rejected: '',
  },

  //currentOperationState
  // states= 0. initiated/edit, 1: processed, 2: submitted
  currentOperationState: 0,

  // miscellanous
  calibratedBy: "",
  approvedBy: "",
  calibratedByImgSign: "",

  DatasheetReadingsAdditionalInfo: [],
  datasheetImages: [],
  Remarks: []
};
