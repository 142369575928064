import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Typography, Modal } from "@mui/material";
import { showComponent } from "../helper/helpers";
import TextEditor from "./textEditor";
import VerifiedIcon from "@mui/icons-material/Verified";
import DangerousIcon from "@mui/icons-material/Dangerous";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { green, orange } from "@material-ui/core/colors";
import { checkDSStatusByCount } from "./countTester";
import { srfListGuide } from "./guide";
import { useSettings } from "./editSrf/fetchHandlers";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import ListWrap from "../commonComponent/listWrap";
import SrfActionButtons from "./srfActionbutton";
import { getFormattedDates } from "../../utils/utils";
import RenderEmailModal from "../../utils/components/renderEmailModal";

const getStatusColor = (status) =>
  status === "yes" ? green[500] : orange[500];

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "Sr. No.", minWidth: 80 },
  { id: "customerName", label: "Client", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 40 },
  {
    id: "Division",
    label: "Division",
    align: "left",
    minWidth: 40,
  },
  {
    id: "Department",
    label: "Department",
    align: "left",
    minWidth: 40,
  },
  {
    id: "calType",
    label: "Calibration Type",
    minWidth: 40,
    align: "left",
  },
  {
    id: "serviceReqNumber",
    label: "Service Req Number",
    minWidth: 40,
    align: "left",
  },

  {
    id: "commitedDate",
    label: "Commited delivery Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "entryDate",
    label: "Issue Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "userName",
    label: "Created by",
    minWidth: 40,
    align: "left",
  },
];


function createData(
  id,
  customerName,
  address,
  companyHierarchy,
  calType,
  serviceReqNumber,
  commitedDate,
  receiptDate,
  clientId,
  userName,
  dcNumber,
  dcDate,
  entryDate,
  approvedby
) {
  return {
    id,
    customerName,
    address,
    companyHierarchy,
    calType,
    serviceReqNumber,
    commitedDate,
    receiptDate,
    clientId,
    userName,
    dcNumber,
    dcDate,
    entryDate,
    approvedby
  };
}

export default function SrfsList(props) {
  const srfListGuides = srfListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [srfList, setSrfList] = React.useState([]);
  const [dBStatus, setDBStatus] = React.useState("");
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "service Request Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const validCompanies = localStorage.getItem("validClients");
  const userName = localStorage.getItem("userName");
  const [openProgressModal, setOpenProgressModal] = React.useState(false);
  const [srfRowId, setsrfRowId] = React.useState(null);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [srfNumber, setSrfNumber] = React.useState("");
  const [invoiceStatus, setInvoiceStatus] = React.useState("");
  const [srfAnalyticsData, setsrfAnalyticsData] = React.useState([]);
  const [pushIArray, setPushIArray] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [rowDaata, setRowDaata] = React.useState([]);
  
  const [isCSRF, setIsCSRF] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [printmodalopen, setPrintModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [settings, fetchSettings] = useSettings({});
  const [open, setOpen] = React.useState(false);
  const [selectedColumn, SetSelectedColumn] = React.useState([]);
  const [progressSearchBy, setProgressSearchBy] = React.useState("DUCID");
  const [progressSearchKey, setProgressSearchKey] = React.useState("");
  const [progressSearched, setProgressSearched] = React.useState(false);
  const [progressStatusFinalData, setProgressStatusFinalData] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleOpenTimeline = (id, customerName, serviceReqNumber) => {
    //row.id,row.customerName,row.serviceReqNumber
    updateSRFAnalytics(id);
    setCompanyName(customerName);
    setSrfNumber(serviceReqNumber);
    setOpenProgressModal(true);
    setsrfRowId(id);
  };

  const handleCloseimeline = () => {
    setOpenProgressModal(false);
  };
  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);
  const handlePrintModalOpen = (id) => {
    setSelectedRowId(id);
    setPrintModalOpen(true);
  };
  const handlePrintModalClose = () => {
    setPrintModalOpen(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "and pr.companyName",
        "service Request Number": "and pl.serviceReqNumber",
      }[searchBy];
      whereConditions = ` ${colName} like '%${searchKey
        .replaceAll("/", "_")
        .replaceAll("#", "_")}%'`;
    }

    if (to != null && from != null) {
      const { fromDate, toDate } = getFormattedDates(from, to);
      if (whereConditions === "") {
        whereConditions = " and ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` pl.entryDate between '${fromDate}' and '${toDate}' `;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? "  " : "") + `and pr.branch in (${branch})`
        : "";

    // whereConditions += (whereConditions ? " and " : "") + " pl.status = 1 ";

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (pl.createdby=${userId})`
          : ` and (pl.createdby=${userId})`;
      // whereConditions +=
      //   whereConditions.length > 0 && validCompanies
      //     ? ` or pr.id in (${validCompanies}))`
      //     : ` )`;
    }

    if (props.isCount) {
      const today = new Date().toISOString().slice(0, 10);
      whereConditions += (whereConditions ? " and " : " and ") + `DATE(pl.lastmodified) = '${today}'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT pl.approvedby, pl.companyHierarchy, pl.clientId, pr.companyName, pl.serviceReqNumber, pl.calType, pl.committedDeliveryDate, pl.receiptDate, pl.id, GROUP_CONCAT(a.address SEPARATOR ' || ') AS addresses, pl.entryDate, u.userName FROM srfs AS pl LEFT JOIN clients AS pr ON pl.clientId = pr.id LEFT JOIN users AS u ON u.id = pl.createdby LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(CONCAT(',', pl.address, ','), ',', ',,'), ',,', ',')) > 0 WHERE pl.status = 1 ${whereConditions} GROUP BY pl.id order by pl.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM srfs AS pl LEFT JOIN clients AS pr ON pl.clientId = pr.id LEFT JOIN users AS u ON u.id = pl.createdby LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(CONCAT(',', pl.address, ','), ',', ',,'), ',,', ',')) > 0 WHERE pl.status = 1 ${whereConditions}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    // var data = {
    //   query:
    //     userType == "2"
    //       ? `select count(*) as no_of_rows from srfs where createdby=${userId}`
    //       : `select count(*) as no_of_rows from srfs`,
    // };
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getTotalRows", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function getSrfsList() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setSrfList([...res.data]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getSrfsList", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };


  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);
  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname); 
  setSearched(true);
    getSrfsList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("service Request Number");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
    navigate("/inward");
  };
  function getSrfAnalytics() {
    const url = `${BASE_URL}dynamic`;

    const Payload = {
      query: `SELECT srfAnalytics.*, srfInstruments.instrumentId, srfInstruments.DUCID , srfInstruments.serialNumber  FROM srfAnalytics LEFT JOIN srfInstruments ON srfAnalytics.srfInstrumentId = srfInstruments.id WHERE srfAnalytics.srfId =${srfRowId}`,
    };
    axiosWithToken
      .post(url, Payload)
      .then((res) => {
        setsrfAnalyticsData(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getSrfAnalytics", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }
  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getInstrumentsList", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };
  const generatePushIArray = (instrumentArray) => {
    const newPushIArray = [];

    for (let i = 0; i < instrumentArray.length; i++) {
      if (instrumentArray[i].instrumentName) {
        newPushIArray.push({
          label:
            instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
        });
      }
    }

    return newPushIArray;
  };

  useEffect(() => {
    setPushIArray(generatePushIArray(instrumentArray));
  }, [instrumentArray]);

  useEffect(() => {
    getSrfAnalytics();
  }, [srfRowId]);

  useEffect(() => {
    checkDSStatusByCount(setDBStatus);   
    getInstrumentsList();
    fetchSettings(["CSRFS","companyHierarchy",]);
  }, []);
  
  useEffect(() => {
    if (settings["CSRFS"]?.value === "true") {
      setIsCSRF(true);
    }
  }, [settings]);

  useEffect(() => {
    getSrfsList();
    getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched==false) {
    getSrfsList();
    getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${id})&_fields=id`)
      .then((res) => {
        Promise.all([
          axiosWithToken.delete(url + `srfs/${id}`),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `datasheets/${d.id}`)
          ),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `certificates/${d.id}`)
          ),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `srfInstruments/${d.id}`)
          ),
        ])
          .then((res) => {
            toast("Srf deleted successfully!");
            setTimeout(refresh, 1000);
          })
          .catch((err) => {
            if (err.message !== "request_aborted") {
              toast.error(
                <h6
                  onClick={() =>
                    openErrorModal(err.message, "submitSiteDelete", "Srf List")
                  }
                >
                  <u>Error: {err.message}</u>
                </h6>
              );
            }
          });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  const generateRowDaata = (srfList) => {
    const newRowDaata = [];

    for (let i = 0; i < srfList.length; i++) {
      newRowDaata.push(
        createData(
          srfList[i].id,
          srfList[i].companyName,
          srfList[i].addresses,
          srfList[i].companyHierarchy,
          srfList[i].calType == 1 ? "NABL" : srfList[i].calType == 2 ? "IAS" : "NON NABL",
          srfList[i].serviceReqNumber,
          moment(srfList[i].committedDeliveryDate).format("DD-MM-YYYY"),
          moment(srfList[i].receiptDate).format("DD-MM-YYYY"),
          srfList[i].clientId,
          srfList[i].userName,
          srfList[i].dcNumber,
          moment(srfList[i].dcDate).format("DD-MM-YYYY"),
          moment(srfList[i].entryDate).format("DD-MM-YYYY"),
          srfList[i].approvedby,
        )
      );
    }

    return newRowDaata;
  };

  const renderPrintModal = () => {
    return (
      <Modal
        open={printmodalopen}
        onClose={handlePrintModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 250,
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "24px",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1, width: "100%" }}>
                <span>Download SRF AND CSRF</span>
              </div>
              <div style={{ flexBasis: "50px" }}></div>
              <div style={{ flex: 1, width: "100%", display: "flex" }}>
                <div style={{ flex: 1, width: "100%" }}>
                  <Tooltip
                    title="Ignore Warning and adds Reading"
                    placement="top-start"
                  >
                    <Button
                      component={Link}
                      to={`/srf/printSrf/${selectedRowId}`}
                      variant="contained"
                      size="small"
                      sx={{ m: 0, ml: 2 }}
                    >
                      SRF Print
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ flex: 1, width: "100%" }}>
                  <Tooltip
                    title="Clear readings but keeps instrument"
                    placement="top-start"
                  >
                    <Button
                      component={Link}
                      to={`/srf/printCsrf/${selectedRowId}`}
                      variant="contained"
                      size="small"
                      sx={{ m: 0, ml: 2 }}
                    >
                      CSRF Print
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const progressModalRender = () => {



    //srfAnalyticsData
    function getStatusObject(progressPercent) {
      if (progressPercent === null) {
        return {
          inwordStatus: "no",
          dataSheetStatus: "no",
          approveStatus: "no",
          dispatchedStatus: "no",
        };
      }

      const inwordStatus = progressPercent >= 25 ? "yes" : "no";
      const dataSheetStatus = progressPercent >= 50 ? "yes" : "no";
      const approveStatus = progressPercent >= 75 ? "yes" : "no";
      const dispatchedStatus = progressPercent >= 90 ? "yes" : "no";

      return {
        inwordStatus,
        dataSheetStatus,
        approveStatus,
        dispatchedStatus,
      };
    }

    const statusObjects = srfAnalyticsData?.map((item) => ({
      id: item.id,
      srfId: item.srfId,
      srfInstrumentId: item.srfInstrumentId,
      status: getStatusObject(item.progressPercent),
      instrumentId: item.instrumentId,
      DUCID: item.DUCID,
      serialNumber: item.serialNumber,
    }));

    let data = {
      query: `SELECT CASE WHEN EXISTS (SELECT 1 FROM customInvoice WHERE serviceReqNumber LIKE '%\"${srfNumber}\"%') THEN 'yes' ELSE 'no' END AS Response`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setInvoiceStatus(res.data[0].Response);
      })
      .catch((err) => {
        console.log("InvoiceStatus data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "renderTimeline", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });



      const search1 = () => {
        const filteredData = statusObjects.filter(obj => {
          const searchKey = [
            { key: "DUCID", label: "DUCID" },
            { key: "serialNumber", label: "Serial Number" },
          ]?.find((ele)=>ele.label == progressSearchBy )?.key // Get the key to search by
          return obj[searchKey]?.toLowerCase().includes(progressSearchKey.toLowerCase()); // Case-insensitive search
        }) || [];

        setProgressStatusFinalData(filteredData)
        setProgressSearched(true);
      };



      const clearSearch1 = () => {

        setProgressStatusFinalData(statusObjects)
        // Logic to clear the search and reset
        setProgressSearched(false);
        setProgressSearchKey("");
      };

let mainData = progressStatusFinalData?.length > 0 ? progressStatusFinalData : statusObjects



    return (
      <Modal open={openProgressModal} onClose={handleCloseimeline}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 1100,
            height: 550,
            overflowY: "scroll",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{ float: "right" }}
            onClick={() => setOpenProgressModal(false)}
          >
            X
          </Button>
          <TableContainer component={Paper}>
          <Box>
      <Table>
        <TableBody>
          <TableRow  >
            <TableCell style={{ border: "none", fontWeight: "bold", fontSize: "20px", padding: 0, width:"200px" }}>
              Client:
            </TableCell>
            <TableCell style={{ border: "none", fontSize: "18px", padding: 0 }}>
              {companyName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: "none", fontWeight: "bold", fontSize: "20px", padding: 0 }}>
              SRF Number:
            </TableCell>
            <TableCell style={{ border: "none", fontSize: "18px", padding: 0 }}>
              {srfNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: "none", fontWeight: "bold", fontSize: "20px", padding: 0 }}>
              Total Instruments:
            </TableCell>
            <TableCell style={{ border: "none", fontSize: "18px", padding: 0 }}>
              {statusObjects?.length}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: "none", fontWeight: "bold", fontSize: "20px", padding: 0 }}>
              Invoice Status:
            </TableCell>
            <TableCell
              style={{ border: "none", fontSize: "18px", padding: 0, color: getStatusColor(invoiceStatus) }}
            >
              {invoiceStatus === "yes" ? <VerifiedIcon /> : <DangerousIcon />}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "5px 0px"}}
    >
        <Grid item xs={12} sm={6} md={2} lg={2.5}></Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.5}></Grid>
      <Grid item xs={12} sm={5} md={2} lg={2.5}>
        <Autocomplete
        className="Listinputcss"
          sx={{
            m: 0,
            width: "100%",
          }}
          size="small"
          value={progressSearchBy}
          id="srflist-searchby"
          options={[
            { key: "DUCID", label: "DUCID" },
            { key: "serialNumber", label: "Serial Number" },
          ]}
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setProgressSearchBy(newInputValue);
     
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2.5}>
        <TextField
        className="Listinputcss"
          style={{ width: "100%" }}
          id="srflist-searchbytext"
          label={"enter " + progressSearchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          value={progressSearchKey}
          onChange={(e) => {
            setProgressSearchKey(e.target.value)

          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
      <Button
          id="srflist-searchbtn"
          variant="contained"
          size="small"
          color={progressSearched ? "error" : "primary"}
          onClick={() => {
            progressSearched ? clearSearch1() : search1();
            // handleFilterClose();
          }}
        >
         {progressSearched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
        </Grid>

      </Grid>

    </Box>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Sr. No
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Instrument Name
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    DUCID
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Serial Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Inward Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    DataSheet Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Approve Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Dispatched Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mainData?.map((item , index) => (
                  <TableRow key={item.id}>
                       <TableCell style={{ fontWeight: "bold" }}>
                      {index + 1 }
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {item?.instrumentId
                        ? pushIArray
                            ?.filter(
                              (e) =>
                                e?.label?.split(",")[0] == item?.instrumentId
                            )?.[0]
                            ?.label.split(",")[1]
                        : ""}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {item?.DUCID}
                    </TableCell>
                    <TableCell>{item?.serialNumber}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.inwordStatus),
                      }}
                    >
                      {item.status.inwordStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dataSheetStatus),
                      }}
                    >
                      {item.status.dataSheetStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.approveStatus),
                      }}
                    >
                      {item.status.approveStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dispatchedStatus),
                      }}
                    >
                      {item.status.dispatchedStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    );
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "5px 0px"}}
    >
      <Grid item xs={6} sm={3} md={1} lg={1.5}  id="srflist-datefrom">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            slotProps={{
              textField: { size: "small", fullWidth: true },
            }}
            className="Listinputcss"
            label="From"
            value={from ? new Date(from) : null}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              setFrom(newValue);
              setSearched(false);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={3} md={1} lg={1.5}  id="srflist-dateto">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            slotProps={{
              textField: { size: "small", fullWidth: true },
            }}
            className="Listinputcss"
            label="To"
            value={to ? new Date(to) : null}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              setTo(newValue);
              setSearched(false);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
            style={{ marginRight: "20px" }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={5} md={2} lg={2.5}>
        <Autocomplete
        className="Listinputcss"
          sx={{
            m: 0,
            width: "100%",
          }}
          size="small"
          value={searchBy}
          id="srflist-searchby"
          options={[
            { key: "serviceReqNumber", label: "service Request Number" },
            { key: "companyName", label: "Client" },
          ]}
          isOptionEqualToValue={(option, value) => option.label === value}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2.5}>
        <TextField
        className="Listinputcss"
          style={{ width: "100%" }}
          id="srflist-searchbytext"
          label={"enter " + searchBy}
          size="small"
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
        <Button
          id="srflist-searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
         {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  const srfApproved = (id) => {
    let url = BASE_URL;
    axiosWithToken
    .patch(url + `srfs/${id}`, { approvedby: userId })
      .then((res) => {
        toast("SRF is approved!");
        refresh();
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }

  useEffect(() => {
    setRowDaata(generateRowDaata(srfList));
  }, [srfList]);

  return (
    <ListWrap>
      {dBStatus === "sick" ? (
        <h1 style={{ color: "red" }}>
          {" "}
          Record count is mismatching.
          <br /> please contact the support team. Tel: +91 9769847865
        </h1>
      ) : null}
      {dBStatus !== "sick" && (
        <>
          {filterModal()}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Toolbar id="srflist-createsrfbtn" className="ListCreatebutton">
              <Button
                variant="contained"
                size="small"
                component={Link}
                to="/inward/editInward/0"
                sx={{
                  ':hover': {
                    color: '#fff', /* or any other color you prefer */
                  },
                }}
              >
                <b>create Inward</b>
              </Button>
            </Toolbar>
            <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
              <Tooltip title="User Guide" placement="top-start">
                <Button
                  onClick={() => {
                    srfListGuides.drive();
                  }}
                  style={{ marginRight: "5px" }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </Box>
          </div>
          {!isMobile && filterSection()}
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: isMobile ? "70vh" : "70vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                size="small"
                id="srflist-table"
                sx={{ borderCollapse: "collapse" }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => 
                    {
                      if (!settings?.companyHierarchy?.value && (column.id === "Division" || column.id === "Department")) {
                        return null; 
                      }
                      return (
                       <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                       );
                      })}
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowDaata?.length > 0 ? (
                    rowDaata?.map((row, index) => {
                      const comp = row.companyHierarchy ? row.companyHierarchy?.split(",") : [];
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ textWrap: "nowrap"}}
                        >
                          {columns.map((column) => {
                            let value = row[column.id];
                            if (!settings?.companyHierarchy?.value && (column.id === "Division" || column.id === "Department")) {
                              return null; 
                            }
                            if (column.id == "id") {
                              value = index + 1;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                              {["customerName", "serviceReqNumber"].includes(column.id) ? (
                                <Link to={`/inward/editInward/${row.id}`} className="custom-link" title="Edit SRF">
                                  {value}
                                </Link>
                              ) : column.id === "Division" && comp[0] ? (
                                comp[0]?.trim()
                              ) : column.id === "Department" && comp[1] ? (
                                comp[1]?.trim()
                              ) : (
                                column.format && typeof value === "number" ? column.format(value) : value
                              )}
                            </TableCell>
                            );
                          })}
                          <SrfActionButtons
                            row={row}
                            isCSRF={isCSRF} 
                            editAccess={editAccess}
                            showComponent={showComponent}
                            handleOpen={handleOpen}
                            SetSelectedColumn={SetSelectedColumn}
                            submitSiteDelete={submitSiteDelete}
                            srfApproved={srfApproved}
                            handlePrintModalOpen={handlePrintModalOpen}
                            handleOpenTimeline={handleOpenTimeline}
                            />
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align="center">
                       <h6><b>Data Not Found</b></h6>
                      </TableCell>
                    </TableRow>
                  )} 
                </TableBody>
              </Table>
              <RenderEmailModal
                open={open}
                handleClose={handleClose}
                selectedColumn={selectedColumn}
                refresh={refresh}
                formType={"srf"}
              />
            </TableContainer>
            <TablePagination
              id="srflist-pagination"
              rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component="div"
              colSpan={3}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              ActionsComponent={TablePaginationActions}
            />
            <ToastContainer />
          </Box>
          {openProgressModal && progressModalRender()}
          {renderPrintModal()}
          {errormodalIsOpen && (
            <ErrorModal
              errormodalIsOpen={errormodalIsOpen}
              closeErrorModal={closeErrorModal}
              errorMessage={errorMessage}
            />
          )}
        </>
      )}
    </ListWrap>
  );
}
